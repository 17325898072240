import { nanoid } from 'nanoid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ListProps } from '../protocols';
import { Content, Option } from '../styles';

export const Lista: React.FC<ListProps> = ({
  listaWidth,
  listaHeight,
  inputRef,
  setIsOpen,
  filter,
  setFilter,
  selected,
  setSelected,
  options,
  changeSelected,
}) => {
  const ITEM_TO_RENDER = 50;

  const [renderItem, setRenderItem] = useState(ITEM_TO_RENDER);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [mouseFocusedIndex, setMouseFocusedIndex] = useState(-1);

  const loadMore = useCallback(
    () => setRenderItem((prev) => prev + ITEM_TO_RENDER),
    [],
  );

  const onResetStates = useCallback(() => {
    setFocusedIndex(-1);
    setMouseFocusedIndex(-1);
  }, []);

  /**
   * wrapperRef
   * Ref da lista de opções utilizado para fechar a lista ao clicar fora
   */
  const wrapperRef = useRef<HTMLUListElement | null>(null);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const wrapperElement = wrapperRef.current;
      if (!wrapperElement) return;

      const opts = wrapperElement.getElementsByTagName(
        'li',
      ) as HTMLCollectionOf<HTMLLIElement>;
      if (!opts.length) return;

      setSelected({ value: '', label: '' });

      const pageSize = Math.floor(
        wrapperElement.clientHeight / opts[0].clientHeight,
      );

      const handleNavigation = (newIdx: number) => {
        setFocusedIndex(Math.max(0, Math.min(newIdx, opts.length - 1)));
      };

      const keyActions: Record<string, () => void> = {
        ArrowDown: () => handleNavigation(focusedIndex + 1),
        ArrowUp: () => handleNavigation(focusedIndex - 1),
        PageDown: () => handleNavigation(focusedIndex + pageSize),
        PageUp: () => handleNavigation(focusedIndex - pageSize),
        Home: () => handleNavigation(0),
        End: () => handleNavigation(opts.length - 1),
        Enter: () => {
          const activeIndex =
            focusedIndex >= 0 && focusedIndex < opts.length
              ? focusedIndex
              : mouseFocusedIndex;
          if (activeIndex >= 0 && activeIndex < opts.length) {
            opts[activeIndex].click();
            (document.activeElement as HTMLElement)?.blur();
          }
        },
      };

      if (keyActions[event.key]) {
        event.preventDefault();
        keyActions[event.key]();
      }
    },
    [focusedIndex, mouseFocusedIndex, setSelected],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    const wrapperElement = wrapperRef.current;
    if (!wrapperElement) return;

    const opts = wrapperElement.getElementsByTagName('li');
    if (focusedIndex < 0 || focusedIndex >= opts.length) return;

    const focusedOption = opts[focusedIndex] as HTMLElement;
    const { clientHeight: wrapperHeight } = wrapperElement;
    const { offsetTop: optionOffsetTop, offsetHeight: optionHeight } =
      focusedOption;

    wrapperElement.scrollTop =
      optionOffsetTop - wrapperHeight / 2 + optionHeight / 2;
    focusedOption.focus();
  }, [focusedIndex, wrapperRef, options]);

  useEffect(() => {
    const preventScroll = (e: WheelEvent) => {
      if (wrapperRef.current && wrapperRef.current.contains(e.target as Node))
        setFocusedIndex(mouseFocusedIndex);
    };

    document.addEventListener('wheel', preventScroll, { passive: false });
    return () => document.removeEventListener('wheel', preventScroll);
  }, [mouseFocusedIndex, wrapperRef]);

  useEffect(() => {
    const wrapperElement = wrapperRef.current;
    if (!wrapperElement) return;

    const opts = Array.from(
      wrapperElement.getElementsByTagName('li'),
    ) as HTMLLIElement[];
    if (!opts.length || !selected) return;

    opts.forEach((opt, idx) => {
      if (opt.textContent === selected.label) {
        setMouseFocusedIndex(idx);
        setFocusedIndex(idx);
      }
    });
  }, [selected]);

  const handleScroll = useCallback(() => {
    if (
      Number(wrapperRef.current?.scrollTop) +
        Number(wrapperRef.current?.clientHeight) >=
      Number(wrapperRef.current?.scrollHeight)
    ) {
      loadMore();
    }
  }, [loadMore]);

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  /**
   * useCloseOptions
   * Fecha lista de opções ao clicar fora
   */
  function useCloseOptions(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (inputRef) inputRef.current.value = '';
          setFilter('');
          setIsOpen(false);
          onResetStates();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useCloseOptions(wrapperRef);

  /**
   * handleClick
   * Seleciona item da lista
   */
  const handleClick = useCallback(
    (option: { label: string; value: number | string }) => {
      setSelected(option);
      changeSelected(option);
      setIsOpen(false);
      onResetStates();
    },
    [changeSelected, setIsOpen, setSelected, onResetStates],
  );

  /**
   * renderOptions
   * Renderiza lista de opções
   */
  const renderOptions = useCallback(() => {
    if (options !== undefined) {
      if (options.length <= 0) {
        return (
          <Option className="not-found">Nenhum registro encontrado.</Option>
        );
      }
      const newOptions = options.filter((option) =>
        option.label
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase()
          .includes(filter.normalize('NFD').replace(/[\u0300-\u036f]/g, '')),
      );
      if (newOptions.length <= 0) {
        return (
          <Option key={nanoid()} className="not-found">
            Nenhum registro encontrado.
          </Option>
        );
      }

      return (
        <InfiniteScroll
          dataLength={renderItem}
          next={loadMore}
          hasMore={renderItem < newOptions.length}
          loader={<small>Carregando...</small>}
          height={listaHeight}
        >
          {newOptions.slice(0, renderItem).map((option, idx) => {
            const itemClassNames = [
              selected?.value === option?.value ? 'selected' : '',
              focusedIndex === idx ? 'focused' : '',
            ]
              .filter(Boolean)
              .join(' ');

            return (
              <Option
                key={String(option?.value)}
                title={option.label}
                className={itemClassNames}
                onMouseEnter={() => setMouseFocusedIndex(idx)}
                onMouseLeave={() => setMouseFocusedIndex(-1)}
                onClick={() => {
                  handleClick(option);
                  if (inputRef) inputRef.current.value = option.label;
                  setFilter('');
                }}
                onKeyPress={() => {
                  handleClick(option);
                  if (inputRef) inputRef.current.value = option.label;
                  setFilter('');
                }}
              >
                <span style={{ marginRight: '0.625rem' }}>
                  {option.icon && option.icon}
                </span>
                {option.label}
              </Option>
            );
          })}
        </InfiniteScroll>
      );
    }
    return <Option className="not-found">Nenhum registro encontrado.</Option>;
  }, [
    filter,
    handleClick,
    inputRef,
    listaHeight,
    loadMore,
    options,
    renderItem,
    selected?.value,
    setFilter,
    focusedIndex,
  ]);

  return (
    <Content listaWidth={listaWidth}>
      <ul
        ref={wrapperRef}
        style={options?.length ? { height: listaHeight } : {}}
      >
        {options && renderOptions()}
      </ul>
    </Content>
  );
};
